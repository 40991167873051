<template>
  <node :title="config.name" :show-error="showError" :content="content" :error-info="errorInfo"
        @selected="$emit('selected')" @delNode="$emit('delNode')" @insertNode="type => $emit('insertNode', type)"
        placeholder="请设置抄送人" header-bgc="#3296fa" header-icon="el-icon-s-check"/>
</template>

<script>
import Node from './Node'

export default {
  name: "CcNode",
  props:{
    config:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { Node },
  data() {
    return {
      showError: false,
      errorInfo: '',
    }
  },
  computed: {
    content() {
      const config = this.config.props
      switch (config.assignedType) {
        case "ASSIGN_USER":
          if (config.assignedUser.length > 0) {
            let texts = []
            config.assignedUser.forEach(org => texts.push(org.name))
            return String(texts).replaceAll(',', '、')
          } else {
            return '请指定抄送人'
          }
        case "LEADER_TOP":
          return '多级主管依次抄送'
        case "LEADER":
          return config.leader.level > 1 ? '发起人的第 ' + config.leader.level + ' 级主管' : '发起人的直接主管'
        default:
          return '未知设置项😥'
      }
    }
  },
  methods: {
    getFormItemById(id) {
      return this.$store.state.design.formItems.find(item => item.id === id)
    },
    //校验数据配置的合法性
    validate(err) {
      this.errorInfo = ''
      this.showError = false
      try {
        this.showError = !this[`validate_${this.config.props.assignedType}`](err)
        return this.showError
      } catch (e) {
        return true;
      }
    },
    validate_ASSIGN_USER(err){
      if(this.config.props.assignedUser.length > 0){
        return true;
      }else {
        this.errorInfo = '请指定抄送人员'
        err.push(`${this.config.name} 未指定抄送人员`)
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
